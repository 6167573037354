import * as React from "react";
import { portfolioState } from "../redux/selectors"
import { fillPortfolio } from '../redux/modules/portfolio'
import { checkStrLength } from '../helpers'
// import { useSelector, useDispatch } from "react-redux";
// import axios from 'axios';
// import { BASE_URL } from '../api/baseUrl';
import JSONData from "../api/portfolio.json";

export interface IProps {
  tags?: string,
  title?: string;
  thumb_url?: string;
  parent_key?: string;
  link?: string;
  description?: string;
}

export interface IPropsArray {
  portfolio: IProps[];
}

export const PortfolioGrid: React.FC<IProps> = () => {
  const [typeJob, setTypeJob] = React.useState<string>("");
  // const portfolio = useSelector(portfolioState)
  // const dispatch = useDispatch();
  const directionFlex = (i: number) => (i % 2 ? 'flex-md-row-reverse' : 'flex-md-row');
  let isTypePortfolio = (typeJob === "" ? JSONData[0] : JSONData[0].filter((d:any) => d.parent_key === typeJob))
  const keyPortfolio = JSONData[0].map((d:any) => d.parent_key)

  // React.useEffect(() => {
  //   axios.get(`${BASE_URL}/projects`,)
  //     .then(res => res.status === 200 ? dispatch(fillPortfolio(res.data)) : false )
  //     .catch(err => err)
  // }, [])

  return (
    <div className="portfolio_grid my-4 py-5">
      <div className="portfolio_grid_menu px-5 pb-5 mb-4 animated fadeInUp slow">
        <span onClick={() => setTypeJob('')} className={`${'' === typeJob && 'active'}`}>All Projects</span>
        { [...new Set(keyPortfolio)].map((d:any,i:number) => 
          (<span key={i} onClick={() => setTypeJob(d)} className={`${d === typeJob && 'active'}`}>{d}</span>)
        )}
      </div>
      <div className="portfolio_grid_box ">
        { isTypePortfolio.length > 0 ? isTypePortfolio.map((d : IProps,i: number) => 
        <div key={i} className={`portfolio_grid_box_item d-flex flex-column flex-md-row ${directionFlex(i)}`}>
          <div className="col-xs-12 col-md-6 p-0 d-flex">
            <img src={d.thumb_url} className="w-100 d-block m-auto" alt="portfolio img"/>
          </div>
          <div className="col-xs-12 col-md-6 px-4 py-4">
            <h5 className="mb-3">{d.title}</h5>
            <h6 className="my-4">{d.tags}</h6>
            <p>{!!d.description && checkStrLength(d.description)}</p>
            <a href={d.link} className="btn-outlined mt-3" target="_blank">View website</a>
          </div>
        </div>) : <p>Loading ...</p>}
      </div>
    </div>
  );
};