import * as React from "react";
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import "../styles/screens/Portfolio.scss";
import {PortfolioGrid} from "../components/PortfolioGrid";

const Portfolio = () => {
  // const [from, setFrom] = React.useState<string>('Бендеры');
  return (
    <Layout>
      <Helmet>
        <title>Разработка web и mobile в Тирасполе</title>
      </Helmet>
      <section className="portfolio py-5 d-flex justify-content-center">
        <div className="container">
          <div className="description text-center">
            <h2 className="title animated fadeInUp slow">Our works</h2>
            <p className="small_content w-75 pt-4 animated fadeInUp slow">
              We are known by our works. Clients from across the world 
              have put their trust on our abilities and it has been an 
              enriching experience to delight them with the best what 
              technology and creativity can offer. Take a look at some 
              of our recent projects, and you are sure to realise what 
              are talking about.
            </p>
          </div>
          <PortfolioGrid />
        </div>
      </section>
    </Layout>
  );
};

export default Portfolio;